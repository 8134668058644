/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Outlet, useLocation } from "react-router-dom";
import Header from "../../components/Header/index";
import "../../styles/common.css";
import "../../styles/prelogin/prelogin.css";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "../../components/Reducers/MessageReducer";
// import LoginSignUpBg from "../../assets/prelogin/login_signup_bg.png";

const PreloginLayout = () => {
  const currentYear = new Date().getFullYear();

  const location = useLocation();
  const dispatch = useDispatch()

  useEffect(() => {
    if (!location.pathname.includes("/dospeedo/login")) {
      dispatch(setMessage(null));
    }
  }, [location.pathname.includes("/dospeedo/login")])

  return (
    <>
      {/*Header  */}
      <Header />

      {/* Child component */}
      <div className="container mx-auto bg-white  p-0 prelogin_layout">
        {/* <div className="login_background_img">
          <img src={LoginSignUpBg} alt="" />
        </div> */}
        <div>
          <Outlet />
        </div>

        {/* Footer */}
        <footer className="bg-white prelogin_footer  flex items-center justify-center">
          <div className="prelogin_footer_text">
            <span> Copyright {currentYear} DoSpeedo</span>
          </div>
        </footer>
      </div>
    </>
  );
};

export default PreloginLayout;
