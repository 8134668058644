import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeOffcanvas, selectIsOffcanvasOpen } from '../../../../components/Reducers/NotificationReducer';
import CloseIcon from "../../../../assets/icons/closewhitebg.svg"
import { getRequest } from "../../../../interceptor/AxiosConfig";
import * as apiEndpoint from "../../../../apis/postlogin/PostloginApis"
// import ErrorMessageHandling from "../../../../utilsfunction/ErrorHandling";
import Error from "../../../../components/Alert/Error";
import BellIcon from "../../../../assets/icons/bell.svg"

const NotificationsOffcanvas = () => {
    const dispatch = useDispatch();

    // Use the selector to access the isOpen state
    const isOpen = useSelector(selectIsOffcanvasOpen);
    const [errorMessage, setErrorMessage] = useState(false)
    const [notificationList, setNotificationList] = useState(null)


    const getNotification = async () => {
        await getRequest(`${apiEndpoint.notificationAPIEndpoint}`)
            .then((response) => {
                if (response.status === 200) {
                    setNotificationList(response.data.data)
                }
            }).catch((error) => {
                // setErrorMessage(ErrorMessageHandling(error))
                // setErrorMessage("")
            })
    }
    useEffect(() => {
        getNotification()

        // // call fetchData every 10 seconds
        // const intervalId = setInterval(getNotification, 10000);

        // // fetch data immediately when component mounts
        // getNotification();

        // // clean up the interval when the component unmounts
        // return () => clearInterval(intervalId);
    }, []);

    return (
        <div>
            {errorMessage && <Error errorMessage={errorMessage} />}
            <div
                style={{ marginTop: "0px" }}
                className={`fixed inset-0 z-40 flex h-full justify-end ${isOpen ? "translate-x-0" : "translate-x-full"
                    } transition-transform duration-300`}
            >
                <div
                    className="fixed inset-0 bg-black opacity-90"
                    onClick={() => dispatch(closeOffcanvas())}
                >
                </div>

                <div className="offcanvas_body relative bg-white w-10/12 md:w-4/12 h-full p-0">
                    <div className="offcanvas_head flex justify-between items-center p-4">
                        <h3 className="heading">Notification</h3>

                        <img src={CloseIcon} alt="Close" className="cursor-pointer" onClick={() => dispatch(closeOffcanvas())} width={"24px"} height={"24px"} />

                    </div>
                    <ul className="space-y-3 p-4">
                        {notificationList?.map((value, index) => {
                            return <div key={index} className="notification_box flex gap-2 items-center">
                                <img src={BellIcon} alt="" />

                                <div className="p-2 flex flex-col gap-2">
                                    <span className="content_text2">{value?.message}</span>
                                    <span className="content_text4">{value?.created_at?.split("T")[0]}</span>
                                </div>
                            </div>
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default NotificationsOffcanvas;



