/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { LocalStorage } from "../../utilsfunction/Encrypt";
import AppleStoreFooterIcon from "../../assets/download/appleStoreBtnFooter.svg";
import GoogleStoreFooterIcon from "../../assets/download/googleStoreBtnFooter.svg";
import CompanyLogo1 from "../../assets/dospeedo.svg";
// import fbIcon from "../../assets/icons/fb.svg";
import fbIcon from "../../assets/icons/facebook.png"
// import instaIcon from "../../assets/icons/iGram.svg";
import instaIcon from "../../assets/icons/insta.png"
// import ytIcon from "../../assets/icons/yTube.svg";
import ytIcon from "../../assets/icons/youtube.png"
// import chatIcon from "../../assets/icons/sChat.svg";
import chatIcon from "../../assets/icons/snapchat.png"
// import linkIcon from "../../assets/icons/linkedIn.svg";
import linkIcon from "../../assets/icons/linkedin.png"
// import pinIcon from "../../assets/icons/pinterest.svg";
import pinIcon from "../../assets/icons/pinterest.png"
// import twitterIcon from "../../assets/icons/twitter.svg";
import twitterIcon from "../../assets/icons/X.png"
// import tbIcon from "../../assets/icons/tb.svg";
import tbIcon from "../../assets/icons/tumblr.png"
// import rdIcon from "../../assets/icons/rd.svg";
import rdIcon from "../../assets/icons/reddit.png"
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as routePath from "../../routes";

const Footer = () => {
    let localstorage = new LocalStorage();
    const auth = localstorage.getItem("1");

    const navigate = useNavigate();

    const location = useLocation()

    const navigateToSection = (target, type) => {
        if (type === "same") {
            return `${location.pathname}#${target}`
        }
        else {
            return `/${routePath.homePath}#${target}`
        }
    };

    return (
        <div>
            {/* Footer  */}
            <footer
                footer
                className="footer_home container mx-auto text-white py-8"
            >
                <div className="w-full grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 opacity-95">
                    {/* <div className="footer_top w-full flex justify-between">
                        <div className="logo ">
                            <img src={CompanyLogo1} alt="Company Logo" />
                        </div>
                        <div className="app_btn flex gap-4">
                            <button>
                                <img src={AppleStoreFooterIcon} alt="Apple Store" />
                            </button>
                            <button>
                                <img src={GoogleStoreFooterIcon} alt="Google Store" />
                            </button>
                        </div>
                    </div> */}
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-8 lg:gap-20 footer_bottom">
                        <div className="footerLogo">
                            <div className="logo ">
                                <img src={CompanyLogo1} alt="Company Logo" />
                            </div>
                            <div className="description my-5">
                                Looking to hire a maid? DoSpeedo is your one-stop destination for providing reliable and professional maids for your cleaning needs. Experience hassle-free hiring today!
                            </div>
                            <div className="app_btn flex gap-4">
                                <button>
                                    <img src={AppleStoreFooterIcon} alt="Apple Store" />
                                </button>
                                <Link to={`https://play.google.com/store/apps/details?id=com.dospeedo`} target="_blank">
                                    <img src={GoogleStoreFooterIcon} alt="Google Store" />
                                </Link>
                            </div>
                            <div className="social_options">
                                <a href='https://www.facebook.com/dospeedo' title='Facebook' target='_blank' rel="noreferrer"><img src={fbIcon} alt="" width={"250"} height={"250"} /></a>
                                <a href='https://www.instagram.com/dospeedo/' title='Instagram' target='_blank' rel="noreferrer"><img src={instaIcon} alt="" width={"250"} height={"250"} /></a>
                                <a href='https://www.youtube.com/@dospeedo' title='YouTube' target='_blank' rel="noreferrer"><img src={ytIcon} alt="" width={"250"} height={"250"} /></a>
                                <a href='https://www.snapchat.com/' title='SnapChat' target='_blank' rel="noreferrer"><img src={chatIcon} alt="" width={"250"} height={"250"} /></a>
                                <a href='https://www.linkedin.com/company/dospeedo' title='LinkedIn' target='_blank' rel="noreferrer"><img src={linkIcon} alt="" width={"250"} height={"250"} /></a>
                                <a href='https://in.pinterest.com/dospeedo/' title='Pinterest' target='_blank' rel="noreferrer"><img src={pinIcon} alt="" width={"250"} height={"250"} /></a>
                                <a href='https://x.com/do_speedo' title='Twitter' target='_blank' rel="noreferrer"><img src={twitterIcon} alt="" width={"250"} height={"250"} /></a>
                                <a href='https://www.tumblr.com/dospeedo' title='TB' target='_blank' rel="noreferrer"><img src={tbIcon} alt="" width={"250"} height={"250"} /></a>
                                <a href='https://www.reddit.com/user/No-View1355/' title='RD' target='_blank' rel="noreferrer"><img src={rdIcon} alt="" width={"250"} height={"250"} /></a>
                            </div>

                        </div>
                        <div className="company_options">
                            <h4 className="head mb-2">Company</h4>
                            <ul>
                                <li>
                                    <a href="#" className="text-gray-400 hover:text-white"
                                        onClick={() =>
                                            navigate(
                                                `/${routePath.aboutUsPath}`
                                            )
                                        }
                                    >
                                        About Us
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="text-gray-400 hover:text-white"
                                        onClick={() => navigate(`/${routePath.privacyPolicyPath}`)}
                                    >
                                        Privacy Policy
                                    </a>
                                </li>
                                {/* <li>
                                    <a href="#" className="text-gray-400 hover:text-white">
                                        Careers
                                    </a>
                                </li> */}
                                <li>
                                    <a href="#" className="text-gray-400 hover:text-white"
                                        onClick={() => navigate(`/${routePath.termsAndConditionsPath}`)}
                                    >
                                        Terms & Conditions
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="text-gray-400 hover:text-white"
                                        onClick={() => navigate(`/${routePath.refundPolicy}`)}
                                    >
                                        Refund Policy
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="customer_options">
                            <h4 className="head mb-2">Customers</h4>
                            <ul>
                                <li>
                                    <Link to={navigateToSection("services", "")} className="text-gray-400 hover:text-white">
                                        Categories
                                    </Link>
                                </li>
                                <li>
                                    <Link to={navigateToSection("testimonials", `${location.pathname == "/about-us" ? "same" : ""}`)} className="text-gray-400 hover:text-white">
                                        Reviews
                                    </Link>
                                </li>
                                <li>
                                    <Link to={navigateToSection("blogs", `${location.pathname == "/about-us" ? "same" : ""}`)} className="text-gray-400 hover:text-white">
                                        Blog
                                    </Link>
                                </li>
                                <li>
                                    <a href="#" className="text-gray-400 hover:text-white"
                                        onClick={() =>
                                            navigate(
                                                `/${routePath.contactUsPath}`
                                            )
                                        }
                                    >
                                        Contact Us
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="partner_options">
                            <h4 className="head mb-2">Partners</h4>
                            {!auth && (
                                <ul>
                                    <li>
                                        <a
                                            className="text-gray-400 hover:text-white cursor-pointer"
                                            onClick={() =>
                                                navigate(
                                                    `/${routePath.preloginPath}/${routePath.RegistrationPath}`
                                                )
                                            }
                                        >
                                            Register
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="text-gray-400 hover:text-white cursor-pointer"
                                            onClick={() =>
                                                navigate(
                                                    `/${routePath.preloginPath}/${routePath.LoginPath}`
                                                )
                                            }
                                        >
                                            Login
                                        </a>
                                    </li>
                                </ul>
                            )}
                        </div>

                    </div>
                    {/* <div className="mt-8 text-center">
                        <p className="text-gray-400">&copy; 2024 DoSpeedo. All rights reserved.</p>
                    </div> */}
                </div>
            </footer>
        </div>
    )
}

export default Footer