/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "../../../styles/common.css";
import "../../../styles/prelogin/prelogin.css";

const Footer = ({ currentYear }) => {
    return (
        <div className="footer customer_footer mx-auto p-0 container">
            {/* Footer */}
            <footer className="bg-white prelogin_footer flex items-center justify-center">
                <div className="prelogin_footer_text">
                    <span> Copyright {currentYear} DoSpeedo</span>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
