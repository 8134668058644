import React from 'react';

// import fbIcon from "../../assets/icons/fb.svg";
import fbIcon from "../../assets/icons/facebook.png"
// import instaIcon from "../../assets/icons/iGram.svg";
import instaIcon from "../../assets/icons/insta.png"
// import ytIcon from "../../assets/icons/yTube.svg";
import ytIcon from "../../assets/icons/youtube.png"
// import chatIcon from "../../assets/icons/sChat.svg";
import chatIcon from "../../assets/icons/snapchat.png"
// import linkIcon from "../../assets/icons/linkedIn.svg";
import linkIcon from "../../assets/icons/linkedin.png"
// import pinIcon from "../../assets/icons/pinterest.svg";
import pinIcon from "../../assets/icons/pinterest.png"
// import twitterIcon from "../../assets/icons/twitter.svg";
import twitterIcon from "../../assets/icons/X.png"
// import tbIcon from "../../assets/icons/tb.svg";
import tbIcon from "../../assets/icons/tumblr.png"
// import rdIcon from "../../assets/icons/rd.svg";
import rdIcon from "../../assets/icons/reddit.png"

const socialMediaLinks = {
    facebook: "https://www.facebook.com/sharer/sharer.php?u=",
    twitter: "https://twitter.com/intent/tweet?text=",
    linkedin: "https://www.linkedin.com/shareArticle?mini=true&url=",
    pinterest: "https://pinterest.com/pin/create/button/?url=",
    tumblr: "https://www.tumblr.com/widgets/share/tool?canonicalUrl=",
    discord: "https://discord.com/channels/@me",
};

const ReferSocialModal = ({ closeModal }) => {
    const shareUrl = encodeURIComponent("https://www.dospeedo.com");
    const message = encodeURIComponent("Check out this amazing site that I found! It's really useful: ");

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-90 z-50 overflow-hidden">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6">
                <h2 className="heading_text1 mb-4">Refer a Friend</h2>
                <p className="content_text mb-6">
                    Share this link with your friends and help them discover us!
                </p>
                <div className="grid grid-cols-6 gap-4 mb-6">
                    {/* facebook */}
                    <a
                        href={`${socialMediaLinks.facebook}${shareUrl}&quote=${message}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="py-2 text-white flex justify-center items-center"
                    >
                        <img src={fbIcon} alt="" width={"250"} height={"250"} />
                    </a>
                    {/* instagram */}
                    <button
                        onClick={() => navigator.clipboard.writeText(`Check out this amazing site that I found! It's really useful: https://www.dospeedo.com`)}
                        className="py-2 text-white flex justify-center items-center"
                    >
                        <img src={instaIcon} alt="" width={"250"} height={"250"} />
                    </button>

                    {/* youtube */}
                    <button
                        onClick={() => navigator.clipboard.writeText(`Check out this amazing site that I found! It's really useful: https://www.dospeedo.com`)}
                        className="py-2 text-white flex justify-center items-center"
                    >
                        <img src={ytIcon} alt="" width={"250"} height={"250"} />
                    </button>

                    {/* snapchat */}
                    <button
                        onClick={() => navigator.clipboard.writeText(`Check out this amazing site that I found! It's really useful: https://www.dospeedo.com`)}
                        className="py-2 text-white flex justify-center items-center"
                    >
                        <img src={chatIcon} alt="" width={"250"} height={"250"} />
                    </button>

                    {/* linkedin */}
                    <a
                        href={`${socialMediaLinks.linkedin}${shareUrl}&summary=${message}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="py-2 text-white flex justify-center items-center"
                    >
                        <img src={linkIcon} alt="" width={"250"} height={"250"} />
                    </a>

                    {/* pinterest */}
                    <a
                        href={`${socialMediaLinks.pinterest}${shareUrl}&description=${message}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="py-2 text-white flex justify-center items-center"
                    >
                        <img src={pinIcon} alt="" width={"250"} height={"250"} />
                    </a>

                    {/* twitter */}
                    <a
                        href={`${socialMediaLinks.twitter}${message} ${shareUrl}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="py-2 text-white flex justify-center items-center"
                    >
                        <img src={twitterIcon} alt="" width={"250"} height={"250"} />
                    </a>

                    {/* tumblr */}
                    <a
                        href={`${socialMediaLinks.tumblr}${shareUrl}&caption=${message}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="py-2 text-white flex justify-center items-center"
                    >
                        <img src={tbIcon} alt="" width={"250"} height={"250"} />
                    </a>

                    {/* discord */}
                    <a
                        href={`${socialMediaLinks.discord}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="py-2 text-white flex justify-center items-center"
                    >
                        <img src={rdIcon} alt="" width={"250"} height={"250"} />
                    </a>
                </div>
                <button
                    onClick={closeModal}
                    className="w-full btn_primary"
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default ReferSocialModal