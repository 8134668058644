/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SubscriptionIcon from "../../../../assets/icons/subscriptonIcon.svg";
import EditIcon from "../../../../assets/icons/EditIcon.svg";
import "../../../../styles/postlogin/styles.css";
import { useNavigate } from "react-router-dom";
import * as routePath from "../../../../routes";
import EyeOpen from "../../../../assets/prelogin/eye_open.svg";
import EyeClose from "../../../../assets/prelogin/eye_close.svg";

import EditInlineIcon from "../../../../assets/icons/edit_inline.svg";
import CancelInlineIcon from "../../../../assets/icons/canceledit_icon.svg";
import SaveInlineIcon from "../../../../assets/icons/saveedit_icon.svg";

import * as apiEndpoint from "../../../../apis/postlogin/PostloginApis";
import { getRequest, postRequest } from "../../../../interceptor/AxiosConfig";
// import ErrorMessageHandling from "../../../../utilsfunction/ErrorHandling";
import Error from "../../../../components/Alert/Error";
import Success from "../../../../components/Alert/Success";
import FormError from "../../../../components/Alert/FormError";

import LogoutIcon from "../../../../assets/logout.svg";

import ReferFriendModal from "../../../../components/modals/ReferModal";
import { useDispatch } from "react-redux";
import { setMessage } from "../../../../components/Reducers/MessageReducer";

const ProfilePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loginType, setLoginType] = useState("")
  const [name, setName] = useState(null);
  const [tempName, setTempName] = useState(name);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);

  const [tempPhone, setTempPhone] = useState(null);
  const [tempEmail, setTempEmail] = useState(null);

  const [isNameEditing, setIsNameEditing] = useState(false);
  const [isPhoneEditing, setIsPhoneEditing] = useState(false);
  const [isEmailEditing, setIsEmailEditing] = useState(false);

  const [showPasswordSection, setShowPasswordSection] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  const [isSubscribed, setIsSubscribed] = useState(false);

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = (values) => {
    let errors = {};

    if (!values?.oldPassword) {
      errors.oldPassword = "Old password is required";
    }
    if (!values?.newPassword) {
      errors.newPassword = "New password is required";
    } else if (values?.newPassword.length < 8) {
      errors.newPassword = "Password must be at least 8 characters long";
    } else if (!/[A-Z]/.test(values?.newPassword)) {
      errors.newPassword =
        "Password must contain at least one uppercase letter";
    } else if (!/[a-z]/.test(values?.newPassword)) {
      errors.newPassword =
        "Password must contain at least one lowercase letter";
    } else if (!/[0-9]/.test(values?.newPassword)) {
      errors.newPassword = "Password must contain at least one number";
    } else if (!/[@$!%*?&]/.test(values?.newPassword)) {
      errors.newPassword =
        "Password must contain at least one special character (@, $, !, %, *, ?, &)";
    }

    if (values?.confirmPassword !== values?.newPassword) {
      errors.confirmPassword = "Passwords do not match";
    }

    return errors;
  };

  useEffect(() => {
    // if there is no errors login() function will be called
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      changePassword();
    }
  }, [formErrors, isSubmit]);

  // function for handling create button event
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validateForm(formData));
    setIsSubmit(true);
  };

  const changePassword = () => {
    setSuccessMessage("");

    postRequest(`${apiEndpoint.changePasswordAPIEndpoint}`, {
      oldPassword: formData.oldPassword,
      newPassword: formData.newPassword,
    })
      .then((response) => {
        setSuccessMessage(response.data.message);
        setFormData({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
        setTimeout(() => {
          navigate(`/`);
          localStorage.clear();
        }, 500);
      })
      .catch((error) => {
        // setErrorMessage(ErrorMessageHandling(error));
        // setErrorMessage("")
      });
  };

  // get customer profile information
  const getCustomerProfile = async () => {
    await getRequest(`${apiEndpoint.profileDataAPIEndpoint}`)
      .then((res) => {
        if (res.status === 200) {
          setEmail(res.data.data.email);
          setName(res.data.data.full_name);
          setPhone(res.data.data.phone_number);
          setProfilePicture(res.data.data.profile);
          setIsSubscribed(res.data.subscriptionStatus)
          setLoginType(res.data.data.login_type)
        }
      })
      .catch((err) => {
        if (err.response) {
          // setErrorMessage(ErrorMessageHandling(err));
          // setErrorMessage("")
        }
      });
  };

  useEffect(() => {
    getCustomerProfile();
  }, []);

  // handle saving the changes
  const handleSaveClick = async (e) => {
    if (e === "name") {
      setTempName(tempName);
      setIsNameEditing(false);
    }

    if (e === "mobile") {
      setTempPhone(tempPhone);
      setIsPhoneEditing(false);
    }

    if (e === "email") {
      setTempEmail(tempEmail);
      setIsEmailEditing(false);
    }

    let payload = {
      full_name: tempName ? tempName : name,
      email: tempEmail ? tempEmail : email,
      country_code: "91",
      phone_number: tempPhone ? tempPhone : phone,
      profile_picture: null,
    };
    await postRequest(`${apiEndpoint.updateProfileAPIEndpoint}`, { ...payload })
      .then((res) => {
        if (res.status === 200) {
          setSuccessMessage("Profile updated successfully");
          setErrorMessage(false);
          getCustomerProfile();
        }
      })
      .catch((err) => {
        // setErrorMessage(ErrorMessageHandling(err));
        // setErrorMessage("")
        setSuccessMessage(false);
      });
  };

  // handle canceling the edit
  const handleCancelClick = (e) => {
    if (e === "name") {
      setIsNameEditing(false);
    }

    if (e === "mobile") {
      setIsPhoneEditing(false);
    }

    if (e === "email") {
      setIsEmailEditing(false);
    }
  };

  // handle profile picture change
  const handleProfilePictureChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setProfilePicture(e.target.result);
      };
      reader.readAsDataURL(file);
    }

    let payload = {
      full_name: tempName ? tempName : name,
      email: tempEmail ? tempEmail : email,
      country_code: "91",
      phone_number: tempPhone ? tempPhone : phone,
      profile_picture: profilePicture,
    };
    await postRequest(`${apiEndpoint.updateProfileAPIEndpoint}`, { ...payload })
      .then((res) => {
        if (res.status === 200) {
          setSuccessMessage("Profile updated successfully");
          setErrorMessage(false);
        }
      })
      .catch((err) => {
        // setErrorMessage(ErrorMessageHandling(err));
        // setErrorMessage("")
        setSuccessMessage(false);
      });
  };

  // trigger file input when edit icon is clicked
  const triggerFileInput = () => {
    document.getElementById("profilePicInput").click();
  };

  // logout user
  // const logout = () => {
  //     localStorage.clear()
  //     navigate(`/`)
  // }

  const logoutUser = async () => {
    await postRequest(`${apiEndpoint.logoutAPIEndpoint}`, {})
      .then((response) => {
        if (response.status === 200) {
          localStorage.clear();
          dispatch(setMessage(response.data.message));
          navigate(`/${routePath.preloginPath}/${routePath.LoginPath}`);
        }
      })
      .catch((err) => {
        // setErrorMessage(ErrorMessageHandling(err));
        // setErrorMessage("")
      });
  };


  // Handle opening and closing the refer friend modal
  const [referFriendModal, setReferFriendModal] = useState(false);
  const toggleReferModal = () => setReferFriendModal(!referFriendModal);

  return (
    <div className="container mx-auto p-0">
      <div className="flex flex-col items-center justify-start bg-gray-100 min-h-screen ">
        <div>
          {errorMessage && <Error errorMessage={errorMessage} />}
          {successMessage && <Success successMessage={successMessage} />}
        </div>
        <div className="w-full profile_bg h-60 relative mb-8">
          <div className="flex flex-col items-center relative top-28">
            <div className="bg-white md:p-8 lg:p-8 xl:p-8 p-4 w-full max-w-lg tabtitlebg">
              <div className="profilePicBox">
                <div className="userPicBox border-4 border-white shadow-md">
                  <img
                    src={profilePicture || "https://via.placeholder.com/165"}
                    alt="Profile"
                    className=""
                    width="165"
                    height="165"
                  />
                </div>
                {isSubscribed && <div className="subscription_icon transform translate-x-10 bg-white p-1 rounded-full">
                  <img
                    src={SubscriptionIcon}
                    alt={"membership"}
                    width="80"
                    height="80"
                  />
                </div>}
                <div
                  className="editprofile_icon transform translate-y-2 bg-white p-1 rounded-full shadow-md cursor-pointer"
                  onClick={triggerFileInput}
                >
                  <img
                    src={EditIcon}
                    alt={"edit profile"}
                    width="40"
                    height="40"
                  />
                </div>
              </div>
              <input
                type="file"
                id="profilePicInput"
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleProfilePictureChange}
              />

              <div
                className={`${errorMessage || successMessage
                  ? ""
                  : "-mt-20 overflow-x-hidden"
                  }`}
              >
                <div className="mb-6 relative">
                  <div className="relative">
                    <input
                      type="text"
                      className={`w-full px-3 pt-5 pb-2 border border-gray-400 rounded-lg ${isNameEditing ? "" : "cursor-not-allowed"
                        }`}
                      value={isNameEditing ? tempName : name}
                      onChange={(e) => setTempName(e.target.value)}
                      disabled={!isNameEditing}
                      placeholder=" "
                    />
                    <span
                      className={`input_placeholder ${!isNameEditing ? "disabled" : ""
                        }`}
                    >
                      Your Name
                    </span>
                  </div>

                  {isNameEditing && (
                    <div className="ml-2 flex save_cancelname">
                      <button
                        className="text-green-500 ml-2"
                        onClick={() => handleSaveClick("name")}
                      >
                        <img src={SaveInlineIcon} alt="save" />
                      </button>
                      <button
                        className="text-red-500 ml-2"
                        onClick={() => handleCancelClick("name")}
                      >
                        <img src={CancelInlineIcon} alt="cancel" />
                      </button>
                    </div>
                  )}
                  {!isNameEditing && (
                    <div className="ml-2 flex editname">
                      <button
                        className="text-green-500 ml-2"
                        onClick={() => setIsNameEditing(!isNameEditing)}
                      >
                        <img src={EditInlineIcon} alt="edit" />
                      </button>
                    </div>
                  )}
                  {/* </div> */}
                </div>

                {/* Phone Input */}
                <div className="mb-6 relative">
                  <div className="relative">
                    <input
                      type="text"
                      className={`w-full px-3 pt-5 pb-2 border border-gray-400 rounded-lg ${isPhoneEditing ? "" : "cursor-not-allowed"
                        }`}
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      placeholder=" "
                      disabled={!isPhoneEditing}
                    />
                    <span
                      className={`input_placeholder input_placeholder_profilephone ${!isPhoneEditing ? "disabled" : ""
                        }`}
                    >
                      Phone Number
                    </span>
                  </div>
                  {isPhoneEditing && (
                    <div className="ml-2 flex save_cancelphone">
                      <button
                        className="text-green-500 ml-2"
                        onClick={() => handleSaveClick("mobile")}
                      >
                        <img src={SaveInlineIcon} alt="save" />
                      </button>
                      <button
                        className="text-red-500 ml-2"
                        onClick={() => handleCancelClick("mobile")}
                      >
                        <img src={CancelInlineIcon} alt="cancel" />
                      </button>
                    </div>
                  )}
                  {!isPhoneEditing && (
                    <div className="ml-2 flex editphone">
                      <button
                        className="text-green-500 ml-2"
                        onClick={() => setIsPhoneEditing(!isPhoneEditing)}
                      >
                        <img src={EditInlineIcon} alt="edit" />
                      </button>
                    </div>
                  )}
                </div>

                {/* Email Input */}
                <div className="mb-6 relative">
                  <div className="relative">
                    <input
                      type="email"
                      className={`w-full px-3 pt-5 pb-2 border border-gray-400 rounded-lg ${isEmailEditing ? "" : "cursor-not-allowed"
                        }`}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder=" "
                      disabled={!isEmailEditing}
                    />
                    <span
                      className={`input_placeholder ${!isEmailEditing ? "disabled" : ""
                        }`}
                    >
                      Email
                    </span>
                  </div>
                  {/* {isEmailEditing && (
                    <div className="ml-2 flex save_cancelemail">
                      <button
                        className="text-green-500 ml-2"
                        onClick={() => handleSaveClick("email")}
                      >
                        <img src={SaveInlineIcon} alt="save" />
                      </button>
                      <button
                        className="text-red-500 ml-2"
                        onClick={() => handleCancelClick("email")}
                      >
                        <img src={CancelInlineIcon} alt="cancel" />
                      </button>
                    </div>
                  )}
                  {!isEmailEditing && (
                    <div className="ml-2 flex editemail">
                      <button
                        className="text-green-500 ml-2"
                        onClick={() => setIsEmailEditing(!isEmailEditing)}
                      >
                        <img src={EditInlineIcon} alt="edit" />
                      </button>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {loginType === "email" && <div className="bg-white p-4 w-full max-w-lg md:mt-72 lg:mt-64 xl:mt-64 mt-60 mb-4 tabtitlebg">
          <div className="flex justify-between items-center">
            <button
              className="w-full py-2 px-3 tabtitle"
              onClick={() => setShowPasswordSection(!showPasswordSection)}
            >
              Change Password
            </button>
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 1.41L4.94467 6L0 10.59L1.52227 12L8 6L1.52227 0L0 1.41Z"
                fill="#13171B"
              />
            </svg>
          </div>
          {showPasswordSection && (
            <div className="mt-4">
              <div className="relative">
                <input
                  type={showOldPassword ? "text" : "password"}
                  className="w-full px-3 pt-5 pb-2 mb-3 border border-gray-300 rounded-lg"
                  placeholder=" "
                  defaultValue={formData?.oldPassword}
                  onChange={(e) => handleChange(e)}
                  name="oldPassword"
                />

                <span className="input_placeholder input_placeholder_op">Old Password</span>

                {formData?.oldPassword ? (
                  <div
                    className="absolute inset-y-0 right-0 flex items-center px-2 cursor-pointer"
                    onClick={() => setShowOldPassword(!showOldPassword)}
                  >
                    {showOldPassword ? (
                      <img src={EyeOpen} alt="Hide Password" />
                    ) : (
                      <img src={EyeClose} alt="See Password" />
                    )}
                  </div>
                ) : null}
              </div>
              {formErrors?.oldPassword && (
                <FormError error={formErrors?.oldPassword} />
              )}
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  className="w-full px-3 pt-5 pb-2 mb-3 border border-gray-300 rounded-lg"
                  placeholder=" "
                  defaultValue={formData?.newPassword}
                  onChange={(e) => handleChange(e)}
                  name={"newPassword"}
                />

                <span className="input_placeholder input_placeholder_np">New Password</span>

                {formData?.newPassword ? (
                  <div
                    className="absolute inset-y-0 right-0 flex items-center px-2 cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <img src={EyeOpen} alt="Hide Password" />
                    ) : (
                      <img src={EyeClose} alt="See Password" />
                    )}
                  </div>
                ) : null}
              </div>
              {formErrors?.newPassword && (
                <FormError error={formErrors?.newPassword} />
              )}
              <div className="relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  className="w-full px-3 pt-5 pb-2 mb-3 border border-gray-300 rounded-lg"
                  placeholder=" "
                  defaultValue={formData?.confirmPassword}
                  onChange={(e) => handleChange(e)}
                  name={"confirmPassword"}
                />

                <span className="input_placeholder input_placeholder_cp">Confirm Password</span>

                {formData?.confirmPassword ? (
                  <div
                    className="absolute inset-y-0 right-0 flex items-center px-2 cursor-pointer"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <img src={EyeOpen} alt="Hide Password" />
                    ) : (
                      <img src={EyeClose} alt="See Password" />
                    )}
                  </div>
                ) : null}
              </div>
              {formErrors?.confirmPassword && (
                <FormError error={formErrors?.confirmPassword} />
              )}
              <div className="flex justify-end w-full mt-3">
                <div className="flex gap-2 w-6/12">
                  <button
                    className="btn_secondary"
                    onClick={() => setShowPasswordSection(!showPasswordSection)}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn_primary"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>}

        {/* Address tab */}
        <div
          className={`bg-white p-4 w-full max-w-lg mb-4 tabtitlebg flex justify-between items-center ${loginType === "email" ? "" : "md:mt-72 lg:mt-64 xl:mt-64 mt-60"}`}
          onClick={() => {
            navigate(
              `/${routePath.CustomerLayoutPath}/${routePath.addressAddEditPath}`
            );
          }}
        >
          <button className="w-full py-2 px-3 tabtitle">Address</button>
          <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 1.41L4.94467 6L0 10.59L1.52227 12L8 6L1.52227 0L0 1.41Z"
              fill="#13171B"
            />
          </svg>
        </div>

        <div
          className="bg-white p-4 w-full max-w-lg mb-4 tabtitlebg flex justify-between items-center"
          onClick={() => toggleReferModal()}
        >
          <button className="w-full py-2 px-3 tabtitle">Refer a friend</button>
          <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 1.41L4.94467 6L0 10.59L1.52227 12L8 6L1.52227 0L0 1.41Z"
              fill="#13171B"
            />
          </svg>
        </div>

        {referFriendModal && <ReferFriendModal toggleModal={toggleReferModal} referFriendModal={referFriendModal} setReferFriendModal={setReferFriendModal} />}

        {/* subscription tab */}
        <div className="bg-white p-4 w-full max-w-lg mb-4 tabtitlebg flex justify-between items-center"
          onClick={() => navigate(`/${routePath.CustomerLayoutPath}/${routePath.myMembershipPlansPath}`)}
        >
          <button className="w-full py-2 px-3 tabtitle">My Membership</button>
          <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 1.41L4.94467 6L0 10.59L1.52227 12L8 6L1.52227 0L0 1.41Z"
              fill="#13171B"
            />
          </svg>
        </div>

        {/* Privacy Policy tab */}
        <div
          className="bg-white p-4 w-full max-w-lg mb-4 tabtitlebg flex justify-between items-center"
          onClick={() => navigate(`/${routePath.privacyPolicyPath}`)}
        >
          <button className="w-full py-2 px-3 tabtitle">Privacy Policy</button>
          <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 1.41L4.94467 6L0 10.59L1.52227 12L8 6L1.52227 0L0 1.41Z"
              fill="#13171B"
            />
          </svg>
        </div>

        {/* Terms & Conditions tab */}
        <div
          className="bg-white p-4 w-full max-w-lg mb-4 tabtitlebg flex justify-between items-center"
          onClick={() => navigate(`/${routePath.termsAndConditionsPath}`)}
        >
          <button className="w-full py-2 px-3 tabtitle">
            Terms & Conditions
          </button>
          <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 1.41L4.94467 6L0 10.59L1.52227 12L8 6L1.52227 0L0 1.41Z"
              fill="#13171B"
            />
          </svg>
        </div>

        <div
          className="cursor-pointer bg-white p-4 w-full max-w-lg mb-4 logout"
          onClick={() => logoutUser()}
        >
          <div className="flex items-center justify-center gap-2">
            <img src={LogoutIcon} alt={""} /> <span>Logout</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
