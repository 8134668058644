// pre login imports
import ForgotPassword from "./pages/Prelogin/ForgotPassword";
import Login from "./pages/Prelogin/Login";
import OtpVerify from "./pages/Prelogin/OtpVerify";
import PreloginLayout from "./pages/Prelogin/PreloginLayout";
import Registration from "./pages/Prelogin/Registration";

// route import
import { Routes, Route, Navigate } from "react-router-dom";
import * as routePath from "./routes";

// post login import

// import Layout from "./pages/Postlogin/Layout/Layout";
import Dashboard from "./pages/Postlogin/maidpages/dashboard";

// private routes
import PrivateRoutes from "./protectedRoute/ProtectedRoute";

// global css
import "./styles/global.css";
import Home from "./pages/Prelogin/home";
import RegistrationSuccess from "./pages/Prelogin/RegistrationSuccess";
import PasswordResetSuccess from "./pages/Prelogin/PasswordResetSuccess";
import ResetPassword from "./pages/Prelogin/ResetPassword";

// postlogin files

import CustomerLayout from "./pages/Postlogin/customerpages";

import Services from "./pages/Postlogin/customerpages/services/index"
import BookService from "./pages/Postlogin/customerpages/services/bookservices/BookService";


// booking files import

import BookingDetails from "./pages/Postlogin/customerpages/bookings/index"
import NotificationsOffcanvas from "./pages/Postlogin/customerpages/notification/Notification";
import ProfilePage from "./pages/Postlogin/customerpages/profile/Profile";
import AddressAddEdit from "./pages/Postlogin/customerpages/profile/AddEditAddress"
import Review from "./pages/Postlogin/customerpages/review/Review";

// add address
import AddressList from "./pages/Postlogin/customerpages/address/BookAgainAddressList"
import BlogDetails from "./pages/Prelogin/home/BlogsDetails";
import AboutUs from "./pages/Prelogin/home/About";
import ContactUs from "./pages/Prelogin/home/ContactUs";
import PrivacyPolicy from "./pages/Postlogin/customerpages/doc/PrivacyPolicy";
import TermsConditions from "./pages/Postlogin/customerpages/doc/TermsAndConditions";
import RefundPolicy from "./pages/Postlogin/customerpages/doc/RefundPolicy";
import SubscriptionPage from "./pages/Postlogin/customerpages/profile/Subscription";
import { LocalStorage } from "./utilsfunction/Encrypt";
import MyMembershipPlan from "./pages/Postlogin/customerpages/profile/MyMembershipPlan";

function App() {
  const localstorage = new LocalStorage();
  const auth = localstorage.getItem("1");

  return (
    <>
      <Routes>
        <Route path="/">
          <Route index element={<Navigate to={`${routePath.homePath}`} replace />} />
          <Route path={`${routePath.homePath}`} element={<Home />} />
          <Route path={`${routePath.blogDetailsPath}/:id`} element={<BlogDetails />} />
          <Route path={`${routePath.aboutUsPath}`} element={<AboutUs />} />
          <Route path={`${routePath.contactUsPath}`} element={<ContactUs />} />

          <Route path={`${routePath.privacyPolicyPath}`} element={<PrivacyPolicy />} />
          <Route path={`${routePath.termsAndConditionsPath}`} element={<TermsConditions />} />
          <Route path={`${routePath.refundPolicy}`} element={<RefundPolicy />} />
        </Route>
        <Route path={`${routePath.preloginPath}`} element={<PreloginLayout />}>
          <Route path={`${routePath.LoginPath}`} element={<Login />} />
          <Route
            path={`${routePath.RegistrationPath}`}
            element={<Registration />}
          />
          <Route
            path={`${routePath.ForgotPasswordPath}`}
            element={<ForgotPassword />}
          />
          <Route
            path={`${routePath.OTPVerificationPath}`}
            element={<OtpVerify />}
          />
          <Route
            path={`${routePath.RegistrationSuccessPath}`}
            element={<RegistrationSuccess />}
          />
          <Route
            path={`${routePath.PasswordResetSuccessPath}`}
            element={<PasswordResetSuccess />}
          />

          <Route
            path={`${routePath.PasswordResetPath}`}
            element={<ResetPassword />}
          />
        </Route>

        {/* customer layout */}
        <Route element={<PrivateRoutes />}>
          <Route
            path={`${routePath.CustomerLayoutPath}`}
            element={<CustomerLayout />}
          >
            {/* services list */}
            <Route
              path={`${routePath.servicesListPath}`}
              element={<Services />}
            />

            {/* book service */}
            <Route
              path={`${routePath.servicesBookingPath}/:id`}
              element={<BookService />}
            />

            {/* booking list */}
            <Route
              path={`${routePath.bookingListPath}`}
              element={<BookingDetails />}
            />

            {/* profile */}
            <Route path={`${routePath.profilePath}`} element={<ProfilePage />}></Route>

            {/* profile add edit address */}
            <Route path={`${routePath.addressAddEditPath}`} element={<AddressAddEdit />}></Route>

            {/* add review */}
            <Route path={`${routePath.reviewPath}/:id`} element={<Review />}></Route>

            {/* add address */}
            <Route path={`${routePath.addressPath}`} element={<AddressList />}></Route>

            {/* subscription */}

            <Route path={`${routePath.subscriptionPath}`} element={<SubscriptionPage />}></Route>

            <Route path={`${routePath.myMembershipPlansPath}`} element={<MyMembershipPlan />}></Route>

          </Route>

        </Route>

        {/* maid layout */}
        {/* <Route element={<PrivateRoutes />}> */}
        {/* <Route path={`${routePath.PostloginLayoutPath}`} element={<Layout />}> */}
        <Route path={`${routePath.dashboard}`} element={<Dashboard />} />
        {/* </Route> */}
        {/* </Route> */}
      </Routes>
      {auth && <NotificationsOffcanvas />}
    </>
  );
}

export default App;
