import React, { useEffect, useState } from 'react'

import { formateDateThree } from "../../../../utilsfunction/Functions";
// import Tick from "../../../../assets/icons/tick.svg"
import { getRequest } from "../../../../interceptor/AxiosConfig";

import * as apiEndpoint from "../../../../apis/postlogin/PostloginApis"
// import ErrorMessageHandling from "../../../../utilsfunction/ErrorHandling"

import Error from "../../../../components/Alert/Error"
// import { useNavigate } from "react-router-dom";
// import * as routePath from "../../../../routes"

const MyMembershipPlan = () => {
    // const navigate = useNavigate()
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    const getSubscriptionDetails = async () => {
        await getRequest(`${apiEndpoint.subscriptionDetailsApiEndpoint}`)
            .then((res) => {
                if (res.status === 200) {
                    setIsSubscribed(res.data.data)
                }
            }).catch((err) => {
                // setErrorMessage("")
                // setErrorMessage(ErrorMessageHandling(err))
            })
    }
    useEffect(() => {
        getSubscriptionDetails()
    }, [])


    return (
        <div>
            {errorMessage && <Error errorMessage={errorMessage} />}
            <div className="container mx-auto min-h-screen">
                <div className="breadcrump text-sm text-gray-500 mb-4 py-6">
                    <div className="flex gap-2 items-center">
                        <span className="heading_first">Profile</span>
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.98027 6.9999C7.98027 7.25081 7.88447 7.50168 7.69327 7.69297L1.67362 13.7126C1.29069 14.0955 0.669847 14.0955 0.287077 13.7126C-0.0956924 13.3298 -0.0956924 12.7091 0.287077 12.3261L5.6136 6.9999L0.287263 1.67366C-0.0955064 1.29073 -0.0955064 0.670073 0.287263 0.287333C0.670033 -0.0957766 1.29088 -0.0957766 1.6738 0.287333L7.69346 6.30683C7.88469 6.49822 7.98027 6.74909 7.98027 6.9999Z" fill="#1B9075" />
                        </svg>
                        <span className="heading_second">My Subscription</span>
                    </div>
                </div>
                {isSubscribed && <div className="bg-white p-0 subscribed">

                    {/* Subscription Details */}
                    <div className="subscribed_header py-6 px-4 flex justify-between items-center">
                        <div className="flex gap-4 items-center p-4">
                            <h2 className="text-xl font-semibold text-white text-center">{isSubscribed?.membershipType?.name}</h2>
                            <div className="text-4xl md:text-5xl font-bold my-4 text-white text-center">{(isSubscribed?.membershipType?.currency || "INR") + " "}{isSubscribed?.membershipType?.price}</div>
                        </div>
                        <div className="flex gap-2">
                            <div className="subscribed_text flex justify-evenly items-center">
                                Starts on : {formateDateThree(isSubscribed?.start_date?.split("T")[0])}
                            </div>
                            <div className="subscribed_text flex justify-evenly items-center">
                                Expire on : {formateDateThree(isSubscribed?.end_date?.split("T")[0])}
                            </div>
                        </div>
                    </div>

                    <div className="py-4 px-8">
                        <div className="benefits space-y-4 text-lg grid grid-cols-2" dangerouslySetInnerHTML={{ __html: isSubscribed ? isSubscribed?.membershipType?.benefits : "" }}>
                            {/* <li className="flex items-center space-x-2">
                                <img src={Tick} alt="" />
                                <span className="subtext">Express service delivery-Cleaning professional will arive within 45 mins after confirmation of order</span>
                            </li>
                            <li className="flex items-center space-x-2">
                                <img src={Tick} alt="" />
                                <span className="subtext">15% discount of all orders</span>
                            </li>
                            <li className="flex items-center space-x-2">
                                <img src={Tick} alt="" />
                                <span className="subtext">*Discount will be applicable on Dospeedo Family Prices</span>
                            </li> */}
                        </div>
                    </div>
                </div>}
                {/* <div className="flex justify-end mt-4 w-full">
                    <div className="flex gap-2 items-center w-4/12">
                        <button className="btn_secondary">
                            Cancel Subscription
                        </button>
                        <button className="btn_primary" onClick={() => { navigate(`/${routePath.CustomerLayoutPath}/${routePath.subscriptionPath}`) }}>
                            See More Plans
                        </button>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default MyMembershipPlan